<template>
  <OutletListComponent :current-outlet="currentOutlet" :set-current-outlet="setCurrentOutlet" />
</template>

<script>
import OutletListComponent from '../OutletListComponent.vue'
export default {
  name: 'OutletList',
  components: {
    OutletListComponent
  },
  created () {
    window.scrollTo(0, 0)
  },
  data () {
    return {
      currentOutlet: null
    }
  },
  methods: {
    setCurrentOutlet (outlet) {
      this.currentOutlet = outlet
      this.$router.push({ name: 'category', query: { outletId: outlet._id } })
    }
  }
}
</script>

<style>
</style>
